export const BUNDLE_CODE_MEALS = "min_meals_number";
export const BUNDLE_CODE_MEALS_MAX = "max_meals_number";
export const BUNDLE_CODE_DEFAULT = "is_default";
export const BUNDLE_CHECKOUT_ENABLED = "checkout_enabled";
export const BUNDLE_CODE_DEFAULT_COUPON_CODE = "default_coupon_code";
export const BUNDLE_CODE_CUSTOM = "bundle_plus";
export const BUNDLE_ATTRIBUTE_TYPE = "bundle_type";
export const PREFEX_CUSTOM_BUNDLE = "Bundle_plus";
export const CUSTOM_BUNDLE_VALUE = "plus";
export const CUSTOM_BUNDLE_CODE = "custom_bundle";
export const CUSTOM_BUNDLE_INCREMENT = 20;
export const CUSTOM_BUNDLE_PRICE = 6.5;
export const MEAL_CODE_NUTRISCORE = "nutri_score";
export const MEAL_CODE_FOOD_PREFERENCE = "food_preference";
export const MEAL_CODE_NEW = "new";
export const MEAL_CODE_BEST = "best_seller";
export const MEAL_CODE_INGREDIENT_DESCRIPTION = "ingredient_description";
export const MEAL_CODE_NUTRITIONAL_VALUE = "nutritional_value";
export const MEAL_CODE_PREPARATION_ADVICE = "preparation_advice";
export const CODE_BUNDLE_MAIN_TAXON = "bundle";
export const CODE_MEAL_MAIN_TAXON = "meal";
export const CODE_EXTRA_MAIN_TAXON = "extra";
export const CODE_LOYALTY = "CREDIT";
export const MEAL_CODE_STORAGE_INSTRUCTIONS = "storage_instructions";
export const PRODUCT_MAX_INCREMENT = 10;
export const BUNDLE_DAILY_TAXON_CODE = "ky_bundle";
export const TRUSTPILOT_SCORE = "4,7/5";
export const MIN_WEEK_PER_PROGRAM = "4";
