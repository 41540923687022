import { ESubscriptionState } from "@middleware/types";
import WrapApi from "@middleware/helpers/api/wrapApi.class";
import { AxiosResponse } from "axios";
import { ICustomer } from "./../types/global/communsType";

export default class Customer extends WrapApi {
  getCustomer = async (customerId: number): Promise<ICustomer | undefined> => {
    const result = await this.getResource("shop/customers/{id}", {
      id: customerId,
    });

    if (result.status !== 200) return;

    return result.data as ICustomer;
  };

  updateCustomer = async (customer: ICustomer): Promise<AxiosResponse> => {
    return await this.putResource(
      "shop/customers/{id}",
      { ...customer },
      {
        id: customer.id,
      }
    );
  };

  pauseSubscription = async (
    subscriptionId: number
  ): Promise<AxiosResponse> => {
    return await this.patchResource(
      "shop/subscriptions/{id}",
      { state: ESubscriptionState.PAUSED },
      { id: subscriptionId }
    );
  };

  unPauseSubscription = async (
    subscriptionId: number
  ): Promise<AxiosResponse> => {
    return await this.patchResource(
      "shop/subscriptions/{id}",
      { state: ESubscriptionState.ON_GOING },
      { id: subscriptionId }
    );
  };

  sponsorship = async (
    customerId: number,
    emailFilleul: string
  ): Promise<AxiosResponse> => {
    return await this.postResource(
      "shop/customers/{id}/sponsorship-email",
      { email: emailFilleul },
      { id: customerId }
    );
  };
  applyCreditToOrder = async (
    customer: ICustomer,
    token: string,
    credits: number
  ): Promise<AxiosResponse> => {
    return await this.putResource(
      "shop/customers/{id}/orders/{tokenValue}/credits/apply",
      {
        credits,
      },
      {
        id: customer.id,
        tokenValue: token,
      }
    );
  };
}
