import Head from "next/head";
import { FC, Fragment, ReactNode } from "react";
import config from "@config/seo_meta.json";
import { client as env } from "@config/env/client";

const storeUrl = env.NEXT_PUBLIC_STORE_URL;
const storeBaseUrl = storeUrl !== "" ? storeUrl : "";

interface OgImage {
  url: string;
  width?: string;
  height?: string;
  alt?: string;
}

interface Props {
  title?: string;
  description?: string;
  robots?: string;
  openGraph?: {
    title?: string;
    type?: string;
    locale?: string;
    description?: string;
    site_name?: string;
    url?: string;
    images?: OgImage[];
  };
  children?: ReactNode;
}

type JSONData = typeof config;

const ogImage = ({ url, width, height, alt }: OgImage, index: number) => {
  // generate full URL for OG image url with store base URL
  const imgUrl =
    storeBaseUrl !== "" ? new URL(url, storeBaseUrl).toString() : url;

  return (
    <Fragment key={`og:image:${index}`}>
      <meta
        key={`og:image:url:${index}`}
        property="og:image"
        content={imgUrl}
      />
      <meta
        key={`og:image:width:${index}`}
        property="og:image:width"
        content={width}
      />
      <meta
        key={`og:image:height:${index}`}
        property="og:image:height"
        content={height}
      />
      <meta
        key={`og:image:alt:${index}`}
        property="og:image:alt"
        content={alt}
      />
    </Fragment>
  );
};

export const SEO: FC<Props> = ({
  title,
  description,
  openGraph,
  robots,
  children,
}) => {
  const customConfig: JSONData = config;

  return (
    <Head>
      <title key="title">
        {title !== undefined
          ? `${customConfig.titleTemplate.replace(/%s/g, title)}`
          : customConfig.title}
      </title>
      <meta
        key="description"
        name="description"
        content={
          description !== undefined ? description : customConfig.description
        }
      />
      <meta
        key="og:type"
        property="og:type"
        content={openGraph?.type ?? customConfig.openGraph.type}
      />
      <meta
        key="og:title"
        property="og:title"
        content={
          openGraph?.title !== undefined
            ? customConfig.openGraph.title
            : title ?? customConfig.title
        }
      />
      <meta
        key="og:description"
        property="og:description"
        content={
          openGraph?.description !== undefined
            ? customConfig.openGraph.description
            : title ?? customConfig.description
        }
      />
      <meta
        key="og:site_name"
        property="og:site_name"
        content={openGraph?.site_name ?? customConfig.openGraph.site_name}
      />
      <meta
        key="og:url"
        property="og:url"
        content={openGraph?.url ?? customConfig.openGraph.url}
      ></meta>
      {openGraph?.locale !== undefined && (
        <meta key="og:locale" property="og:locale" content={openGraph.locale} />
      )}
      {openGraph?.images !== undefined
        ? openGraph.images.map((img, index) => ogImage(img, index))
        : ogImage(customConfig.openGraph.images[0], 0)}
      <meta key="robots" name="robots" content={robots ?? "index,follow"} />
      <meta
        key="googlebot"
        name="googlebot"
        content={robots ?? "index,follow"}
      ></meta>
      <link rel="shortcut icon" href="/favicon.png" />
      {children}
    </Head>
  );
};
