import Image from "next/image";
import { FC, ReactNode } from "react";
import { UrlObject } from "url";
import style from "./EditButton.module.scss";
import { Link } from "../Link/Link";
import { Text } from "../Text/Text";

interface Props {
  editUrl?: string | UrlObject;
  children: ReactNode;
}
export const EditButton: FC<Props> = ({ editUrl, children }) => {
  return editUrl !== undefined ? (
    <div className={style.root}>
      <Link href={editUrl} className="secondaryGreen simple">
        <Text variant="span">{children}</Text>
        <Image
          src="/static/images/icons/green-pencil.png"
          alt="Edit"
          width="15"
          height="15"
        />
      </Link>
    </div>
  ) : null;
};
