import { useRouter } from "next/router";
import cn from "clsx";
import { useTranslation } from "next-i18next";
import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { StepsProgrammes } from "@components/catalog/steps/StepsProgrammes";
import { Logo, Menu } from "@components/commun";
import { Link } from "@components/ui";
import { useAuth, useCart } from "@middleware/hooks";
import { EDevice } from "@middleware/types";
import {
  URL_PAGE_CART,
  URL_PAGE_CUSTOMER_ORDER,
  URL_PAGE_SUCCESS,
} from "@middleware/constants";
import { getFormattedPrice } from "@middleware/helpers";
import styles from "./NavBar.module.scss";

export interface Props {
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
  isUserInProgramFunnel: boolean;
}

export const NavBar: FC<Props> = ({
  showModal,
  logout,
  isUserInProgramFunnel,
}) => {
  const { t } = useTranslation("header");
  const { isAuthenticated } = useAuth();
  const { cart } = useCart();
  const { pathname } = useRouter();
  const showHeader = !isUserInProgramFunnel && pathname !== URL_PAGE_SUCCESS;

  return (
    <>
      {showHeader && (
        <div className={`hide-tab-mobile ${styles.navbar}`}>
          <Menu
            device={EDevice.DESKTOP}
            showModal={showModal}
            logout={logout}
          />
        </div>
      )}

      <div className={cn(styles.brand)}>
        <Link href="/" className="simple">
          <Logo />
        </Link>
      </div>

      {isUserInProgramFunnel && <StepsProgrammes />}

      <div className={styles.rightNav}>
        <ul>
          <li className={`hide-tab-mobile ${styles.linkNumber}`}>
            <a href={`tel:${t("menu.pPhoneNum")}`}>
              {t("menu.pPhoneLabel")}
              <span>{t("menu.appelLocal")}</span>
            </a>
          </li>
          <li className={`hide-mobile ${styles.linkCompte}`}>
            {isAuthenticated === true && (
              <>
                <Link href={URL_PAGE_CUSTOMER_ORDER} className="simple">
                  {" "}
                  {t("menu.pMonEspace")}{" "}
                </Link>
                <a href="#!" className={styles.deconnect} onClick={logout}>
                  {t("menu.pDeconnexion")}
                </a>
              </>
            )}
            {isAuthenticated !== true && (
              <a href="#!" onClick={(e) => showModal(e)} data-cy="modalLogin">
                {t("menu.pCompte")}
              </a>
            )}
          </li>
          <li className={styles.linkCart}>
            <Link href={URL_PAGE_CART} className="simple" prefetch={false}>
              {cart && cart.total > 0
                ? getFormattedPrice(cart.total)
                : t("menu.cart")}
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};
