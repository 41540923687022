import cn from "clsx";
import React, { FC } from "react";
import Image from "next/image";
import { getFormattedDate } from "@middleware/helpers";
import { Text } from "../Text/Text";
import style from "./CalendarDate.module.scss";

interface Props {
  date: string;
  className?: string;
}

export const CalendarDate: FC<Props> = ({ date, className }) => {
  const formatedDate = getFormattedDate(date, {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <div className={cn(className, style.calendar)}>
      <Image
        src="/static/images/icons/CalendarDate.svg"
        alt=""
        width="18"
        height="18"
      />
      <Text variant="span">{formatedDate}</Text>
    </div>
  );
};
