import { StylesConfig } from "react-select";

type IsMulti = false;
export const customStyles: StylesConfig<unknown, IsMulti> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#727885",
    padding: "5px 10px",
    fontSize: "14px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "15px",
    padding: "0 5px",
    margin: 0,
    cursor: "pointer",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "32px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 8px",
    color: "#76bf72",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
