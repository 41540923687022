import { EPaymentMethods, IOrderAddress } from "./../types/commerce/cartType";

export const CART_TOKEN_STORAGE = "cartToken";
export const LAST_CART_TOKEN_STORAGE = "lastPaidOrderToken";
export const AUTHENTICATION_STEP_SECTION = 1;
export const ADDRESS_STEP_SECTION = 2;
export const SHIPPING_STEP_SECTION = 3;
export const PAYMENT_STEP_SECTION = 4;
export const DEFAULT_COUNTRY = "FR";
export const DEFAULT_SHIPMENT_TIME = "8h - 13h";
export const CODE_CHRONO_PRECISE = "chrono_precise";
export const CODE_CHRONO_DOMICILE = "chrono_domicile";
export const DEFAULT_PAYMENT_METHOD = EPaymentMethods.CREDIT_CARD;
export const SUCCESS_SUMMARY_SECTION = 1;
export const FRENCH_COUNTRY_CODE = "33";
export const VALID_FRENCH_PHONE_NUMBER_START = ["6", "7"];
export const VALID_FRENCH_FIXE_NUMBER_START = ["1", "2", "3", "4", "5"];
export const MAX_EXTRAS_PRODUCT = 20;
export const PASSWORD_MIN = 8;
export const PASSWORD_MAX = 25;

export const DEFAULT_ORDER_ADDRESS: IOrderAddress = {
  shippingAddress: {
    countryCode: DEFAULT_COUNTRY,
    complement: "",
    city: "",
    company: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    mobilePhone: "",
    postcode: "",
    provinceCode: "",
    street: "",
  },
  billingAddress: {
    countryCode: DEFAULT_COUNTRY,
    city: "",
    company: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    mobilePhone: "",
    postcode: "",
    provinceCode: "",
    street: "",
    complement: "",
  },
  useDifferentBillingAddress: false,
};
