import Api from "@middleware/api";
import { BundlePromotionDetails } from "@middleware/types";
import { useEffect, useState } from "react";

export const usePromotionDetails = (
  promotionCoupon: string | string[] | undefined
) => {
  const [promotion, setPromotion] = useState<BundlePromotionDetails | null>(
    null
  );

  useEffect(() => {
    const fetchPromotionDetails = async () => {
      if (typeof promotionCoupon === "string") {
        const promotionDetails = await Api.catalog.getCouponPromotion(
          promotionCoupon
        );
        if (promotionDetails) setPromotion(promotionDetails);
      }
    };

    void fetchPromotionDetails();
  }, [promotionCoupon]);

  return { promotion, setPromotion };
};
