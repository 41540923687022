export const SET_STEP_PROGRAMME = "SET_STEP_PROGRAMME";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const OPEN_DROPDOWN = "OPEN_DROPDOWN";
export const CLOSE_DROPDOWN = "CLOSE_DROPDOWN";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_MODAL_VIEW = "SET_MODAL_VIEW";
export const SET_DETAILS = "SET_DETAILS";
export const REMOVE_DETAILS = "REMOVE_DETAILS";
export const SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export const REMOVE_AUTH_DETAILS = "REMOVE_AUTH_DETAILS";
export const LOAD_CART = "LOAD_CART";
export const CLEAR_CART = "CLEAR_CART";
