import axios, { AxiosHeaders } from "axios";
import { AuthInfo, AxiosRequestConfigData } from "@middleware/types";
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { authInitialState } from "@middleware/init";
import { client as env } from "@config/env/client";
import { clearToStorage } from "../global/sessions";
import { isFacebookRequest } from "../global/urls";

const instanceAxios: AxiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
    "X-channel-code": env.NEXT_PUBLIC_CHANNEL_CODE,
    Accept: "application/json",
  },
  validateStatus: function (status) {
    return status >= 200 && status <= 503;
  },
});

instanceAxios.interceptors.request.use(
  (config: AxiosRequestConfig<AxiosRequestConfigData>) => {
    // check if we have user token
    if (typeof localStorage !== "undefined") {
      const userInfo = JSON.parse(
        localStorage.getItem("userInfo") ?? ""
      ) as AuthInfo;
      if (userInfo.user) {
        const token = userInfo.user.token;
        (config.headers as AxiosHeaders).set(
          "Authorization",
          `Bearer ${token}`
        );
      }
    }

    return config;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  }
);

instanceAxios.interceptors.response.use(
  async (response: AxiosResponse) => {
    if (response.status === 401) {
      if (typeof localStorage !== "undefined" && !isFacebookRequest(response)) {
        const loggedOutUserInfo = {
          isAuthenticated: authInitialState.isAuthenticated,
          user: authInitialState.user,
        };
        clearToStorage();
        localStorage.setItem("userInfo", JSON.stringify(loggedOutUserInfo));
      }

      return Promise.resolve(response);
    } else return Promise.reject(response);
  },
  (error: AxiosResponse<AxiosError>) => {
    return Promise.reject(error);
  }
);

instanceAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300)
      return Promise.resolve(response);
    else return Promise.reject(response);
  },
  (error: AxiosResponse<AxiosError>) => {
    return Promise.reject(error);
  }
);

export default instanceAxios;
