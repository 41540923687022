import cn from "clsx";
import Image from "next/image";
import NextLink, { LinkProps } from "next/link";
import { FC, HTMLProps } from "react";
import { client as env } from "@config/env/client";
import Puce3 from "@static/images/icons/daily-btn-fleche.png";
import Puce2 from "@static/images/icons/profil-fech.png";
import Puce from "@static/images/icons/puce-button.png";
import style from "./Link.module.scss";

export const Link: FC<
  LinkProps & Omit<HTMLProps<HTMLAnchorElement>, "href">
> = ({
  as,
  children,
  href,
  onClick,
  replace,
  scroll,
  shallow,
  passHref,
  className,
  target,
  rel,
  ...rest
}) => {
  const rootClassName = cn(
    {
      [style.root]: className?.includes("primary"),
      [style.secondary]: className?.includes("secondary"),
      [style.secondaryGreen]: className?.includes("secondaryGreen"),
    },
    className
  );
  const hidePuce = className?.includes("simple");

  return (
    // @ts-expect-error no error expected
    <NextLink
      as={as}
      href={
        typeof href === "string"
          ? href.replace(env.NEXT_PUBLIC_STORE_URL, "")
          : href
      } // manage prismic urls and externe urls
      passHref={passHref}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      className={rootClassName}
      onClick={onClick}
      target={target}
      rel={rel}
      {...rest}
    >
      <>
        {children}
        {hidePuce !== undefined && !hidePuce && (
          <Image
            src={
              className === "primary"
                ? Puce
                : className === "secondaryGreen"
                ? Puce3
                : Puce2
            }
            width="20"
            height="21"
            alt=""
          />
        )}
      </>
    </NextLink>
  );
};
