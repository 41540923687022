import React, { FC, useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import Api from "@middleware/api";
import { CartContext } from "@middleware/contexts";
import { cartLocalStorageInitialState } from "@middleware/init/cartState";
import { CART_TOKEN_STORAGE } from "@middleware/constants";
import { IOrder, Props } from "@middleware/types";

export const CartProvider: FC<Props> = ({ children }) => {
  const [cartToken, setCartTokenInLocalStorage] = useLocalStorage(
    CART_TOKEN_STORAGE,
    cartLocalStorageInitialState
  );

  const [cartState, setCartState] = useState<IOrder>();

  const setCart = useCallback(
    (cart: IOrder | undefined) => {
      if (cart === undefined) return;

      setCartState(cart);
      if (cartToken?.token !== cart.tokenValue) {
        setCartTokenInLocalStorage({
          token: cart.tokenValue,
        });
      }
    },
    [cartToken, setCartTokenInLocalStorage]
  );

  const loadCart = useCallback(async () => {
    if (cartToken === undefined || cartToken.token === "") {
      const cart = await Api.cart.pickupCart();
      setCart(cart);

      return cart;
    } else {
      const cart = await Api.cart.getCartByToken(cartToken.token);
      setCart(cart);

      return cart;
    }
  }, [setCart, cartToken]);

  const getNewCart = useCallback(async () => {
    const cart = await Api.cart.pickupCart();
    setCart(cart);
  }, [setCart]);

  const value = useMemo(
    () => ({
      cart: cartState,
      loadCart,
      setCart,
      getNewCart,
    }),
    [cartState, loadCart, setCart, getNewCart]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
