import { ICartState, ILocalStorageCart } from "@middleware/types";

export const cartInitialState: ICartState = {
  cart: undefined,
  loadCart: async () =>
    new Promise((resolve) => {
      resolve(null);
    }),
  setCart: () => undefined,
  getNewCart: async () =>
    new Promise((resolve) => {
      resolve();
    }),
};

export const cartLocalStorageInitialState: ILocalStorageCart = {
  token: "",
};
