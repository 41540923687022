import cn from "clsx";
import { useTranslation } from "next-i18next";
import { Link } from "@components/ui";
import { useSteps } from "@middleware/hooks/useSteps";
import { useRouter } from "next/router";
import {
  URL_PAGE_FORMULES,
  URL_PAGE_FORMULES_2,
  URL_PROGRAMME_DAILY,
  URL_PROGRAMME_GOURMANDISE,
} from "@middleware/constants";
import style from "./StepsProgrammes.module.scss";

export const StepsProgrammes = () => {
  const { t } = useTranslation();
  const { currentStep, previousStepsUrl } = useSteps();
  const router = useRouter();
  const useStepsProgramsStyle2 = [
    URL_PAGE_FORMULES,
    URL_PAGE_FORMULES_2,
    URL_PROGRAMME_GOURMANDISE,
    URL_PROGRAMME_DAILY,
  ].includes(router.pathname);

  const steps = [
    {
      step: 1,
      label: useStepsProgramsStyle2
        ? t("buttons.myBundles")
        : t("buttons.bundles"),
    },
    {
      step: 2,
      label: useStepsProgramsStyle2 ? t("buttons.myMeals") : t("buttons.meals"),
    },
    {
      step: 3,
      label: useStepsProgramsStyle2
        ? t("buttons.myExtras")
        : t("buttons.extras"),
    },
    {
      step: 4,
      label: useStepsProgramsStyle2
        ? t("buttons.myPayment")
        : t("buttons.confirmation"),
    },
  ];

  return (
    <div
      className={cn(style.stepsContainer, {
        [style.useStepsProgramsStyle2]: useStepsProgramsStyle2,
      })}
    >
      <div className={style.menuProg}>
        <ul className={style.boxMenu}>
          {currentStep !== null &&
            steps.map((item, stepIndex) => {
              const urlStep =
                stepIndex + 1 < currentStep ? previousStepsUrl[stepIndex] : "";

              return (
                <li
                  key={stepIndex}
                  className={cn(style.stepBox, {
                    [style.activeStep]: item.step === currentStep,
                    [style.prevStep]: item.step < currentStep,
                    [style.nextStep]: item.step - 1 === currentStep,
                  })}
                  data-step={stepIndex + 1}
                >
                  <label>
                    {urlStep !== "" ? (
                      <Link href={urlStep} className="simple">
                        {item.label}
                      </Link>
                    ) : (
                      <span>{item.label}</span>
                    )}
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
