import Image from "next/image";
import { FC } from "react";
import LogoFooter from "@static/images/footer/kitchendiet_logo_footer.png";
import LogoBlanc from "@static/images/kitchendiet-daily-logo.png";

export interface Props {
  type?: string;
}

export const Logo: FC<Props> = ({ type }) => {
  return (
    <>
      {type === "footer" ? (
        <Image alt="Kitchendaily" src={LogoFooter} width="115" height="115" />
      ) : (
        <Image alt="Kitchendaily" src={LogoBlanc} width="177" height="63" />
      )}
    </>
  );
};
